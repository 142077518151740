import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { FormControl } from "@material-ui/core/";
import { debounce } from "/src/util/utilities";
import { isValidPassword } from "/src/util/validations";
import { useStyles } from "./styles";
import { ClipLoader } from "react-spinners";
import Backdrop from "@material-ui/core/Backdrop";
import Snackbar from "../Snackbar";
import {
  confirmationError,
  confirmationSuccess,
} from "../../styles/common.css";

function verifyPasswords(newPassword, reenterPassword) {
  if (newPassword !== reenterPassword) {
    return { success: false, message: "Passwords do not match." };
  }

  if (isValidPassword(newPassword)) {
    return { success: true, message: "" };
  } else {
    return {
      success: false,
      message: "Password is invalid.",
    };
  }
}

const ChangePassword = (props) => {
  const {
    doChangePassword,
    userID: adminUserID,
    isFetching: isLoading,
    response: successMessage,
    errorMessage,
    showSnackBar,
    closeSnackbar,
  } = props;

  const classes = useStyles();

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [reEnterNewPassword, setReEnterNewPassword] = useState("");
  const [formError, setFormError] = useState("");

  const submitForm = () => {
    const { success, message } = verifyPasswords(
      newPassword,
      reEnterNewPassword
    );
    if (!success) {
      setFormError(message);
    } else {
      setFormError(""); // clear form error
      doChangePassword({
        userId: adminUserID,
        password: currentPassword,
        newPassword: newPassword,
      });
    }
  };

  // submit form on enter
  const handleKeyDown = debounce((event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      submitForm();
    }
  }, 1000);

  return (
    <>
      <h4>Change Password</h4>
      <div className={classes.listContainer}>
        <p className={classes.noMargin}>
          New password must meet the following criteria:
        </p>
        <ul className={classes.noMargin}>
          <li>At least 8 characters long</li>
          <li>Contains at least 3 of the following:</li>
          <ul>
            <li>Uppercase letter</li>
            <li>Lowercase letter</li>
            <li>Number</li>
            <li>Special character</li>
          </ul>
        </ul>
      </div>

      <FormControl className={classes.form}>
        <TextField
          label="Current Password"
          type="password"
          size="small"
          color="primary"
          width="100%"
          InputLabelProps={{
            shrink: true,
          }}
          style={{ width: "100%" }}
          className={classes.textLabel}
          value={currentPassword}
          onChange={(e) => setCurrentPassword(e.target.value)}
          onKeyDown={handleKeyDown}
        />
        <TextField
          label="New Password"
          type="password"
          size="small"
          color="primary"
          width="100%"
          InputLabelProps={{
            shrink: true,
          }}
          style={{ width: "100%" }}
          className={classes.textLabel}
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          onKeyDown={handleKeyDown}
        />
        <TextField
          label="Re-Enter New Password"
          type="password"
          size="small"
          color="primary"
          width="100%"
          InputLabelProps={{
            shrink: true,
          }}
          style={{ width: "100%" }}
          className={classes.textLabel}
          value={reEnterNewPassword}
          onChange={(e) => setReEnterNewPassword(e.target.value)}
          onKeyDown={handleKeyDown}
        />
        <Button variant="contained" color="secondary" onClick={submitForm}>
          Save Changes
        </Button>
        <div className={classes.formErrorText}>{formError}</div>
      </FormControl>
      <Backdrop className={classes.backdropStyle} open={isLoading}>
        <ClipLoader sizeUnit="px" size={100} color="#ED0874" loading />
      </Backdrop>
      <Snackbar
        id="message-id"
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={showSnackBar}
        onClose={closeSnackbar}
        className={successMessage ? confirmationSuccess : confirmationError}
        message={successMessage || errorMessage}
      />
    </>
  );
};

export default ChangePassword;
