export function validatePhone(phone) {
  const phoneRegex = /^\d{11}$/;
  return phone && phoneRegex.test(phone);
}

export function validateEmail(email) {
  const emailRegex = /^[\S]+@[\S]+\.[\S]+$/;
  return email && emailRegex.test(email);
}

export function validateZip(zip) {
  const zipRegex = /^\d{5}$/;
  return zip && zipRegex.test(zip);
}

export function isValidPassword(password) {
  let hasNumber = false;
  let hasSpecialChar = false;
  let hasUpperCase = false;
  let hasLowerCase = false;

  for (const char of password) {
    if (/\d/.test(char)) {
      hasNumber = true;
    } else if (/[A-Z]/.test(char)) {
      hasUpperCase = true;
    } else if (/[a-z]/.test(char)) {
      hasLowerCase = true;
    } else if (/[^A-Za-z0-9]/.test(char)) {
      hasSpecialChar = true;
    }

    // Valid password
    if (hasNumber && hasUpperCase && hasLowerCase && hasSpecialChar) {
      break;
    }
  }

  // Check if at least 3 out of 4 categories (number, special, upper, lower) are present
  const validCategoryCount = [
    hasNumber,
    hasSpecialChar,
    hasUpperCase,
    hasLowerCase,
  ].filter(Boolean).length;

  // Password must be at least 8 characters and have 3 out of 4 valid categories
  return validCategoryCount >= 3 && password.length >= 8;
}
