import * as constants from "../../constants";

const reportInitState = {
  notifications: [],
  selectedNotification: {},
};

export function notifications(state = reportInitState, action) {
  switch (action.type) {
    case constants.MEMBER_NOTIFY_GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notifications: action.payload.NotificationJob,
      };
    case constants.MEMBER_GET_NOTIFICATIONS_CONTENT_SUCCESS:
      return {
        ...state,
        selectedNotification: {
          ...state.selectedNotification,
          ...action.payload,
        },
      };
    case constants.MEMBER_CLEAR_NOTIFICATIONS_CONTENT_SUCCESS:
      return {
        ...state,
        selectedNotification: {},
      };
    default:
      return state;
  }
}
