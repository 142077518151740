import React, { useState, useEffect } from "react";
import emlformat from "eml-format";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import Button from "../Button"

const EmailView = ({ closeEmail, notification }) => {
  const [emailContent, setEmailContent] = useState(null)

  useEffect(() => {
    emlformat.read(notification.emailContent, function (error, data) {
      if (!error) {
        setEmailContent(data.html);
      }
    });
  }, []);
  return (
    <Dialog
      open={true}
      contentlabel="Email View"
      ariaHideApp={false}
      fullWidth={true}
      maxHeight="80%"
    >
      <DialogTitle>
        <Typography color="secondary" variant="h6">
          {notification.event}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <div dangerouslySetInnerHTML={{ __html: emailContent }} />
      </DialogContent>
      <Button
        color="secondary"
        onClick={() => window.open(notification.url)}
        disabled={!notification.url || !emailContent}
      >
        Download
      </Button>
      <Button
        color="primary"
        varian="raised"
        onClick={async () => {
          await closeEmail()
        }}
      >
        Close
      </Button>
    </Dialog>
  )
}

export default EmailView;
