import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router";
import { connect } from "react-redux";
import { subNavigation } from "../styles/navigation.css";
import { clearSelectedMember } from "../actions/members";
import { clearIterableUserData } from "../actions/iterable";
import NoteCreationPanel from "./UserNotes/NoteCreationPanel.js";

import { withStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import { ClipLoader } from "react-spinners";
import Snackbar from "./Snackbar";

import { hideSnackBar } from "../actions/members";
import {
  fetchUserIDWithFullMemberInfoNeeded,
  fetchFullMemberInfoNeeded,
} from "../actions/search";

import { confirmationError, confirmationSuccess } from "../styles/common.css";

import { CLEAR_DISPUTE_INFO } from "../../constants";
import { HAS_CSA_SERVICE } from "../util/flags.js";

const clearDisputeInfo = () => ({
  type: CLEAR_DISPUTE_INFO,
});

const styles = (theme) => ({
  backdropStyle: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  header: {
    "& a:hover": {
      transition: "all 0.3s linear",
      backgroundColor: "#eee",
      borderRadius: "10px",
    },
    "& button:hover": {
      transition: "all 0.3s linear",
      backgroundColor: "#aaa",
      borderRadius: "10px",
      color: "white",
    },
  },
});

class Member extends Component {
  constructor(props) {
    super(props);
  }

  componentWillMount() {
    const {
      member,
      onFetchUserIDWithFullMemberInfoNeeded,
      onfetchFullMemberInfoNeeded,
    } = this.props;
    if (member.get("userID") === undefined) {
      onFetchUserIDWithFullMemberInfoNeeded(this.props.params.memberId);
    } else {
      onfetchFullMemberInfoNeeded(this.props.member);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.isCommercial !== nextProps.isCommercial) {
      this.forceUpdate();
    }
  }

  componentWillUnmount() {
    this.props.onClearSelectedMember();
    this.props.onClearDisputeInfo();
    this.props.onClearIterableUserData();
  }

  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.props.onHideSnackBar();
  };

  render() {
    const { children, params, classes, member, hasCsa } = this.props;

    if (
      member.get("service_provider") === null ||
      member.get("service_provider") === undefined
    ) {
      return <></>;
    }

    return (
      <div>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={member.get("showSnackBar")}
          onClose={this.handleClose}
          className={
            member.getIn(["response", "success"])
              ? confirmationSuccess
              : confirmationError
          }
          message={member.getIn(["response", "msg"])}
        />
        <header className={`${classes.header} ${subNavigation}`}>
          {member.get("service_provider") === "proton_meter" && (
            <Fragment>
              <Link
                to={`/members/${params.memberId}/usage`}
                activeStyle={{ textDecoration: "none", color: "black" }}
              >
                Usage
              </Link>{" "}
            </Fragment>
          )}
          <Link
            to={`/members/${params.memberId}/details`}
            activeStyle={{ textDecoration: "none", color: "black" }}
          >
            Details
          </Link>{" "}
          <Link
            to={`/members/${params.memberId}/billing`}
            activeStyle={{ textDecoration: "none", color: "black" }}
          >
            Billing
          </Link>{" "}
          <Link
            to={`/members/${params.memberId}/statements`}
            activeStyle={{ textDecoration: "none", color: "black" }}
          >
            Statements
          </Link>{" "}
          <Link
            to={`/members/${params.memberId}/payment`}
            activeStyle={{ textDecoration: "none", color: "black" }}
          >
            Payment
          </Link>{" "}
          {member.get("service_provider") === "proton_meter" && (
            <Fragment>
              <Link
                to={`/members/${params.memberId}/dnprnp`}
                activeStyle={{ textDecoration: "none", color: "black" }}
              >
                Disconnect Notices
              </Link>{" "}
              <Link
                to={`/members/${params.memberId}/metertransactions`}
                activeStyle={{ textDecoration: "none", color: "black" }}
              >
                Meter Transactions
              </Link>{" "}
              <Link
                to={`/members/${params.memberId}/transferofservice`}
                activeStyle={{ textDecoration: "none", color: "black" }}
              >
                Transfer of Service
              </Link>{" "}
            </Fragment>
          )}
          <Link
            to={`/members/${params.memberId}/renewal`}
            activeStyle={{ textDecoration: "none", color: "black" }}
          >
            Renewal
          </Link>
          <Link
            to={`/members/${params.memberId}/product_change`}
            activeStyle={{ textDecoration: "none", color: "black" }}
          >
            Product Change
          </Link>
          {process.env.REACT_APP_BRAND_INSTANCE_NAME === "think" && (
            <Link
              to={`/members/${params.memberId}/promo`}
              activeStyle={{ textDecoration: "none", color: "black" }}
            >
              Promo
            </Link>
          )}
          {process.env.REACT_APP_ENABLE_ITERABLE === "true" && (
            <Link
              to={`/members/${params.memberId}/iterable`}
              activeStyle={{ textDecoration: "none", color: "black" }}
            >
              Iterable
            </Link>
          )}
          {hasCsa && HAS_CSA_SERVICE && (
            <Link
              to={`/members/${params.memberId}/csa`}
              activeStyle={{ textDecoration: "none", color: "black" }}
            >
              CSA
            </Link>
          )}
          <Link
            to={`/members/${params.memberId}/notes`}
            activeStyle={{ textDecoration: "none", color: "black" }}
          >
            Notes
          </Link>{" "}
          <Link
            to={`/members/${params.memberId}/notifications`}
            activeStyle={{ textDecoration: "none", color: "black" }}
          >
            Notifications
          </Link>{" "}
          <Link
            to={`/members/${params.memberId}/documents`}
            activeStyle={{ textDecoration: "none", color: "black" }}
          >
            Documents
          </Link>{" "}
          {process.env.REACT_APP_ENABLE_REFERRALS === "true" && (
            <>
              <Link
                to={`/members/${params.memberId}/referrals`}
                activeStyle={{ textDecoration: "none", color: "black" }}
              >
                Referrals
              </Link>{" "}
            </>
          )}
          {process.env.REACT_APP_ENABLE_DEVICES === "true" && (
            <>
              <Link
                to={`/members/${params.memberId}/devices`}
                activeStyle={{ textDecoration: "none", color: "black" }}
              >
                Devices
              </Link>{" "}
            </>
          )}
          {member.get("relatedMembers") && (
            <>
              <Link
                to={`/members/${params.memberId}/related_members`}
                activeStyle={{ textDecoration: "none", color: "black" }}
              >
                Related Members
              </Link>{" "}
            </>
          )}
        </header>
        <NoteCreationPanel />
        {children}
        <Backdrop
          className={classes.backdropStyle}
          open={member.get("isFetching")}
        >
          <ClipLoader sizeUnit="px" size={100} color="#ED0874" loading />
        </Backdrop>
      </div>
    );
  }
}

Member.propTypes = {
  children: PropTypes.object,
  params: PropTypes.object,
  isCommercial: PropTypes.bool,
};

Member.defaultProps = {
  children: {},
  params: {},
  isCommercial: false,
};

const mapStateToProps = (state) => ({
  isCommercial: state.selectedMember.get("meters").first()
    ? state.selectedMember.get("meters").first().get("polr_customer_class") !==
      "residential"
    : false,
  member: state.selectedMember,
  hasCsa: state.csa.selected_csa?.csa?.id,
});

const mapDispatchToProps = (dispatch) => ({
  onClearSelectedMember: () => {
    dispatch(clearSelectedMember());
  },
  onClearIterableUserData: () => {
    dispatch(clearIterableUserData());
  },
  onfetchFullMemberInfoNeeded: (member) =>
    dispatch(fetchFullMemberInfoNeeded(member)),
  onFetchUserIDWithFullMemberInfoNeeded: (member) =>
    dispatch(fetchUserIDWithFullMemberInfoNeeded(member)),
  onHideSnackBar: () => dispatch(hideSnackBar()),
  onClearDisputeInfo: () => {
    dispatch(clearDisputeInfo());
  },
});

const ConnectedMember = connect(mapStateToProps, mapDispatchToProps)(Member);

export default withStyles(styles)(ConnectedMember);
