import React from "react";
import { connect } from "react-redux";
import ChangePassword from "../components/ChangePassword";
import { changeUserPassword, closeSnackbar } from "../actions/user";
import selectAdminUserContainer from "../selectors/user";

const ChangePasswordContainer = (props) => <ChangePassword {...props} />;

const mapStateToProps = (state) => ({
  ...selectAdminUserContainer(state)(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  doChangePassword: (userId, password, newPassword) =>
    dispatch(changeUserPassword(userId, password, newPassword)),
  closeSnackbar: () => dispatch(closeSnackbar()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangePasswordContainer);
