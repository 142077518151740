import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { Link } from "react-router";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logout } from "../actions/user";
import Avatar from "@material-ui/core/Avatar";
import Tooltip from "@material-ui/core/Tooltip";

const StyledAvatar = withStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(3),
    },
    width: theme.spacing(3),
    height: theme.spacing(3),
    padding: theme.spacing(1),
    backgroundColor: "rgb(40, 48, 166)",
  },
}))(Avatar);

const getInitials = (firstName, lastName) => {
  const firstInitial = (firstName && firstName.charAt(0).toUpperCase()) || "";
  const lastInitial = (lastName && lastName.charAt(0).toUpperCase()) || "";
  return firstInitial + lastInitial;
};

const ProfileMenu = ({ user }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const initials = getInitials(user.firstName, user.lastName);

  // Menu visible when user is signed in
  return (
    <>
      {user?.userID == null ? (
        <></>
      ) : (
        <div>
          <Tooltip placement="center" title={"Profile Settings"}>
            <StyledAvatar src="/broken-image.jpg" onClick={handleClick}>
              {initials}
            </StyledAvatar>
          </Tooltip>
          <Menu
            elevation={0}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            autoFocus={false}
          >
            <MenuItem>
              <Link
                to={`/profile/change_password`}
                activeStyle={{ textDecoration: "none", color: "black" }}
                style={{ color: "black", textDecoration: "none" }}
                onClick={() => {
                  handleClose();
                }}
              >
                Change Password
              </Link>
            </MenuItem>
            <MenuItem>
              {/* on click trigger logout */}
              <Link
                activeStyle={{ textDecoration: "none", color: "black" }}
                style={{ color: "black", textDecoration: "none" }}
                onClick={() => {
                  handleClose();
                  logout();
                }}
              >
                Logout
              </Link>
            </MenuItem>
          </Menu>
        </div>
      )}
    </>
  );
};

ProfileMenu.propTypes = {
  user: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, null)(ProfileMenu);
