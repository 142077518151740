import * as constants from "../../constants";
import fetch from "../util/api-ajax";
import jwtDecode from "jwt-decode";

function setUserIsFetching(payload) {
  return {
    type: constants.SET_USER_IS_FETCHING,
    payload: payload,
  };
}

function receiveAuthHeaders(headers) {
  return {
    type: constants.RECEIVE_AUTH_HEADERS,
    isFetching: false,
    headers,
  };
}

function requestAuthHeaders() {
  return {
    type: constants.REQUEST_AUTH_HEADERS,
    isFetching: true,
  };
}

function receiveUserLogInError(payload) {
  return {
    type: constants.RECEIVE_USER_LOG_IN_ERROR,
    payload,
  };
}

export function hideSnackBar() {
  return {
    type: constants.HIDE_SNACKBAR,
  };
}

export function updateUserData(payload) {
  return {
    type: constants.USER_LOGGED_IN,
    payload,
  };
}

function requestChangePassword() {
  return {
    type: constants.REQUEST_CHANGE_PASSWORD,
  };
}

function requestChangePasswordSuccess(payload) {
  return {
    type: constants.USER_CHANGE_PASSWORD_SUCCESS,
    payload: payload,
  };
}

function requestChangePasswordFailure(payload) {
  return {
    type: constants.USER_CHANGE_PASSWORD_FAILURE,
    payload,
  };
}

const getUserPermissions = (permissions) => {
  const userPermissions = permissions || {};
  const isTransactionManager =
    userPermissions[constants.PERMISSIONS_METER_TRANSACTION_MANAGER] !== "deny";
  const isMeterDnpManager =
    userPermissions[constants.PERMISIONS_METER_DNP_MANAGER] !== "deny";
  const isUserManager =
    userPermissions[constants.PERMISSIONS_USER_MANAGER] === "allow";
  const isReferralCreditsManager =
    userPermissions[constants.PERMISSIONS_REFERRAL_CREDITS_APPROVAL] ===
    "allow";
  const isFileUploadManager =
    userPermissions[constants.PERMISIONS_FILE_UPLOAD_MANAGER] === "allow";
  const isLMIApproverManager =
    userPermissions[constants.PERMISIONS_LMI_APPROVER_MANAGER] === "allow";
  const isEnrollmentManager =
    userPermissions[constants.PERMISIONS_ENROLLMENT_MANAGER] === "allow";
  const isCSMeterManager =
    userPermissions[constants.PERMISIONS_CS_METER_MANAGER] === "allow";
  const isProductManager =
    userPermissions[constants.PERMISSIONS_PRODUCT_MANAGER] === "allow";
  const isPromoManager =
    userPermissions[constants.PERMISSIONS_PROMO_MANAGER] === "allow";
  const isBillingManager =
    userPermissions[constants.PERMISSIONS_BILLING_MANAGER] === "allow";
  const isCustomerImpersonate =
    userPermissions[constants.PERMISSIONS_CUSTOMER_IMPERSONATE] === "allow";
  return {
    isTransactionManager,
    isReferralCreditsManager,
    isUserManager,
    isMeterDnpManager,
    isFileUploadManager,
    isLMIApproverManager,
    isEnrollmentManager,
    isCSMeterManager,
    isProductManager,
    isPromoManager,
    isBillingManager,
    isCustomerImpersonate,
  };
};

export function login(data) {
  return (dispatch) => {
    dispatch(requestAuthHeaders());
    return fetch(constants.LOGIN, data)
      .then((json) => {
        localStorage.setItem("access_token", json.access_token);
        localStorage.setItem("refresh_token", json.refresh_token);
        localStorage.setItem("userID", json.userID);
        const jwt = jwtDecode(json.access_token);
        if (jwt.permissions[constants.PERMISSIONS_ADMIN_ACCESS] !== "allow") {
          dispatch(receiveUserLogInError({ message: "Insuffient Access" }));
          return;
        }

        // fetch complete user details
        getUserDetails(json.userID)
          .then((res) => {
            const { email, first_name: firstName, last_name: lastName } = res;
            const permissions = getUserPermissions(jwt.permissions);

            // persist user details to localStorage for rehydration
            localStorage.setItem("email", email);
            localStorage.setItem("firstName", firstName);
            localStorage.setItem("lastName", lastName);

            // update store with user details
            dispatch(
              updateUserData({
                userID: json.userID,
                permissions,
                email,
                firstName,
                lastName,
              })
            );

            // this action may be depreicated; consider removing
            dispatch(receiveAuthHeaders(json));
          })
          .catch((err) => {
            // propogate to parent promise
            return Promise.reject(err);
          });
      })
      .catch((error) => {
        dispatch(receiveUserLogInError(error));
      })
      .finally(() => {
        dispatch(setUserIsFetching(false));
      });
  };
}

// fetches admin user's details as well as persists email into local storage
function getUserDetails(userID) {
  return fetch(constants.GET_USER_URL, { userID })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      // propogate to caller
      return Promise.reject(error);
    });
}

export const closeSnackbar = () => {
  return (dispatch) => dispatch({ type: constants.USER_CLOSE_SNACKBAR });
};

export function changeUserPassword({ userId, password, newPassword }) {
  return (dispatch) => {
    dispatch(requestChangePassword());
    return fetch(constants.USER_UPDATE, {
      userID: userId,
      password: password,
      new_password: newPassword,
    })
      .then(() =>
        dispatch(requestChangePasswordSuccess("Successfully updated password"))
      )
      .catch((response) =>
        dispatch(
          requestChangePasswordFailure(
            response.message || "Failed to change password"
          )
        )
      );
  };
}

function hydrateUser(payload) {
  return {
    type: constants.HYDRATE_USER,
    payload,
  };
}

export function hydrate() {
  const email = localStorage.getItem("email");
  const userID = localStorage.getItem("userID");
  const firstName = localStorage.getItem("firstName");
  const lastName = localStorage.getItem("lastName");
  const access_token = localStorage.getItem("access_token");
  const jwt =
    access_token && access_token !== "undefined" ? jwtDecode(access_token) : {};
  const payload = {
    userID,
    email,
    firstName,
    lastName,
    permissions: getUserPermissions(jwt.permissions),
    roles: jwt.roles,
  };
  return (dispatch) => dispatch(hydrateUser(payload));
}

export function logout() {
  localStorage.clear();
  window.location.href = "/login";
  return {
    type: constants.USER_LOGGED_OUT,
  };
}
