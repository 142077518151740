import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    padding: "20px",
    transition: "all 0.2s ease",
    width: "300px",
    border: "1px solid rgba(0, 0, 0, 0.12)",
  },
  textLabel: {
    padding: "10px 0px 20px 0px",
  },
  backdropStyle: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  listContainer: {
    marginBottom: "10px",
  },
  noMargin: {
    margin: "0px !important",
  },
  formErrorText: {
    height: "20px",
    marginTop: "10px",
    color: "red",
  },
  backdropStyle: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));
