import * as constants from "../../constants";
import fetch from "../util/api-ajax";
import * as status from "./pageStatus";

function getNotificationsSuccess(payload) {
  return {
    type: constants.MEMBER_NOTIFY_GET_NOTIFICATIONS_SUCCESS,
    payload,
  };
}

function updateSelectedNotification(payload) {
  return {
    type: constants.MEMBER_GET_NOTIFICATIONS_CONTENT_SUCCESS,
    payload,
  };
}

export function setSelectedNotification(payload) {
  return (dispatch) => dispatch(updateSelectedNotification(payload));
}

function clearEmailContent() {
  return {
    type: constants.MEMBER_CLEAR_NOTIFICATIONS_CONTENT_SUCCESS,
  };
}

export function getNotifications({ memberID, ...args }) {
  return async function (dispatch) {
    dispatch(status.startProcessing());
    try {
      if (memberID) {
        const notifications = [];
        const emails = await getMemberEmails(memberID);
        for (let i = 0; i < emails.length; i++) {
          const res = await fetch(constants.MEMBER_NOTIFY_LIST_NOTIFICATIONS, {
            to_email: emails[i].email,
          });
          notifications.push(...res.NotificationJob);
        }
        await dispatch(
          getNotificationsSuccess({ NotificationJob: notifications })
        );
      } else {
        const res = await fetch(
          constants.MEMBER_NOTIFY_LIST_NOTIFICATIONS,
          args
        );
        await dispatch(getNotificationsSuccess(res));
      }
    } catch (error) {
      dispatch(status.setErrorMessage(error.message));
    } finally {
      dispatch(status.finishProcessing());
    }
  };
}

async function getMemberEmails(memberID) {
  const json = await fetch(constants.MEMBER_GET_URL, { memberID });
  const userID = await json.userID;
  const res = await fetch(constants.GET_USER_EMAILS, { userID });
  return res.emails;
}

export function getNotificationViewUrl(notification) {
  return async (dispatch) => {
    dispatch(status.startProcessing());
    const { message_id } = notification;
    try {
      const res = await fetch(constants.NOTIFICATIONS_GET_VIEW_URL, {
        message_id,
      });
      dispatch(updateSelectedNotification({ ...res, ...notification }));
      dispatch(getEmailFileContent(res.url));
    } catch (error) {
      dispatch(status.setErrorMessage(error.message));
    } finally {
      dispatch(status.finishProcessing());
    }
  };
}

export const getEmailFileContent = (url) => {
  return (dispatch) => {
    try {
      return fetch(url, {}, true, true)
        .then((res) => {
          res.text().then((text) => {
            dispatch(updateSelectedNotification({ emailContent: text }));
          });
        })
        .catch((error) => {
          dispatch(status.setErrorMessage(error.message));
        });
    } catch (error) {
      if (error) {
        return Promise.reject(error);
      }
      return undefined;
    }
  };
};

export function getNotificationEvents(notification) {
  return async (dispatch) => {
    dispatch(status.startProcessing());
    const { message_id } = notification;
    try {
      const res = await fetch(constants.NOTIFICATIONS_GET_EVENTS_URL, {
        message_id,
      });
      dispatch(updateSelectedNotification({ ...res, ...notification }));
    } catch (error) {
      dispatch(status.setErrorMessage(error.message));
    } finally {
      return dispatch(status.finishProcessing());
    }
  };
}

export function resendNotification(notification) {
  return async (dispatch) => {
    dispatch(status.startProcessing());

    try {
      const res = await fetch(constants.NOTIFICATIONS_RESEND_URL, {
        notification,
      });

      if (res.success) {
        dispatch(status.setSuccessMessage("notification sent"));
      }
    } catch (error) {
      dispatch(status.setErrorMessage(error.message));
    } finally {
      return dispatch(status.finishProcessing());
    }
  };
}

export function closeEmail() {
  return (dispatch) => {
    dispatch(clearEmailContent());
  };
}
