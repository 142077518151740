import React from "react";
import { connect } from "react-redux";
import RelatedMembersTable from "../components/members/RelatedMembers";
import PropTypes from "prop-types";

const RelatedMembersContainer = (props) => (
  <RelatedMembersTable
    members={props.member?.get("relatedMembers")}
    meters={props.member?.get("relatedMeters")}
  />
);

const mapStateToProps = (state) => ({
  member: state.selectedMember,
});

RelatedMembersContainer.propTypes = {
  member: PropTypes.object,
};

export default connect(mapStateToProps)(RelatedMembersContainer);
