import * as constants from "../../constants";
import fetch from "../util/api-ajax";
import * as status from "./pageStatus";

function getChannelsSuccess(payload) {
  return {
    type: constants.SALES_CHANNEL_LIST_SUCCESS,
    payload,
  };
}

function getCommissionTypeSuccess(payload) {
  return {
    type: constants.SALES_CHANNEL_COMMISSION_TYPE_SUCCESS,
    payload,
  };
}

export function getChannels() {
  return async (dispatch) => {
    dispatch(status.startProcessing());
    try {
      const res = await fetch(constants.SALES_CHANNEL_LIST, {});
      dispatch(getChannelsSuccess(res));
    } catch (error) {
      dispatch(status.setErrorMessage(error.message));
    } finally {
      dispatch(status.finishProcessing());
    }
  };
}

export function getCommissionType() {
  return async (dispatch) => {
    dispatch(status.startProcessing());
    try {
      const res = await fetch(constants.SALES_CHANNEL_COMMISSION_TYPE, {});
      dispatch(getCommissionTypeSuccess(res));
    } catch (error) {
      dispatch(status.setErrorMessage(error.message));
    } finally {
      dispatch(status.finishProcessing());
    }
  };
}