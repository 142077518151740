import { createSelector } from "reselect";

const adminUser = () => (state) => state.user;

const selectAdminUserContainer = () =>
  createSelector(adminUser(), (state) => {
    return state;
  });

export default selectAdminUserContainer;
