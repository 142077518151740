import React from "react";
import NoteTags from "./NoteTags";
import UserManagement from "./UserManagement";
import SettingsTabs from "../components/common/SwipeableViewTabs";

const Settings = () => (
  <>
    <h4>Settings</h4>
    <SettingsTabs viewNames={["User Management", "Note Tags"]}>
      <UserManagement />
      <NoteTags />
    </SettingsTabs>
  </>
);

export default Settings;
