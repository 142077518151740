import { createSelector } from "reselect";

const salesChannel = () => (state) => state.salesChannel;

const selectSalesChannelContainer = () =>
  createSelector(salesChannel(), (data) => {
    return data;
  });

export default selectSalesChannelContainer;
