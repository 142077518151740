import React, { Component } from "react";
import PropTypes from "prop-types";
import { routerActions } from "react-router-redux";
import { connect } from "react-redux";
import { login, hideSnackBar } from "../actions/user";
import { loginWrapper } from "../styles/login.css";
import Snackbar from "../components/Snackbar";
import { confirmationError } from "../styles/common.css";
import { debounce } from "/src/util/utilities";
import { ClipLoader } from "react-spinners";
import Backdrop from "@material-ui/core/Backdrop";
import { withStyles } from "@material-ui/core/styles";

const LoginStyles = (theme) => ({
  backdropStyle: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
});

function select(state, ownProps) {
  const isAuthenticated = state.user.userID || false;
  const redirect = ownProps.location.query.redirect || "/";
  const showSnackbar = state.user.showSnackBar;
  const response = state.user.response;
  const isLoading = state.user.isFetching;

  return {
    isAuthenticated,
    redirect,
    showSnackbar,
    response,
    isLoading,
  };
}

class LoginContainer extends Component {
  constructor(props) {
    super(props);
    this.onSubmit = debounce(this.onSubmit.bind(this), 300);
  }

  componentWillMount() {
    const { isAuthenticated, replace, redirect } = this.props;
    if (isAuthenticated) {
      replace(redirect);
    }
  }

  componentWillReceiveProps(nextProps) {
    const { isAuthenticated, replace, redirect } = nextProps;
    const { isAuthenticated: wasAuthenticated } = this.props;

    if (!wasAuthenticated && isAuthenticated) {
      replace(redirect);
    }
  }

  onSubmit = (e) => {
    e.preventDefault();
    this.props.login({
      email: this.email.value,
      password: this.password.value,
    });
  };

  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.props.hideSnackBar();
  };

  render() {
    return (
      <React.Fragment>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={this.props.showSnackbar}
          onClose={this.handleClose}
          className={confirmationError}
          message={this.props.response}
        />
        <div className={loginWrapper}>
          <h2>Login</h2>
          <div>
            <label htmlFor="email">
              {"Email:"}
              <input
                type="text"
                ref={(node) => {
                  this.email = node;
                }}
                id="email"
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    this.onSubmit(e);
                  }
                }}
              />
            </label>
          </div>
          <div>
            <label htmlFor="password">
              {"Password:"}
              <input
                type="password"
                ref={(node) => {
                  this.password = node;
                }}
                id="password"
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    this.onSubmit(e);
                  }
                }}
              />
            </label>
          </div>
          <button
            className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect mdl-button--accent"
            onClick={this.onSubmit}
          >
            {"Login"}
          </button>
        </div>
        <Backdrop
          className={this.props.classes.backdropStyle}
          open={this.props.isLoading}
        >
          <ClipLoader sizeUnit="px" size={100} color="#ED0874" loading />
        </Backdrop>
      </React.Fragment>
    );
  }
}

LoginContainer.propTypes = {
  isAuthenticated: PropTypes.bool,
  redirect: PropTypes.string,
  login: PropTypes.func.isRequired,
  replace: PropTypes.func.isRequired,
  response: PropTypes.string,
  showSnackbar: PropTypes.bool,
  hideSnackBar: PropTypes.func.isRequired,
};

LoginContainer.defaultProps = {
  isAuthenticated: false,
  redirect: "/",
  showSnackBar: false,
  response: "",
};

export default withStyles(LoginStyles)(
  connect(select, {
    login,
    hideSnackBar,
    replace: routerActions.replace,
  })(LoginContainer)
);
