import { mergeRight } from "ramda";
import * as C from "../../constants";

const initialState = {
  userID: "",
  email: "",
  firstName: "",
  lastName: "",
  isFetching: false,
  showSnackBar: false,
  response: "",
  errorMessage: "",
  roles: [],
};

const userUpdate = (state = initialState, action) => {
  switch (action.type) {
    case C.HYDRATE_USER: {
      const { userID, firstName, lastName, email, permissions, roles } =
        action.payload;
      const user = {
        userID,
        firstName,
        lastName,
        email,
        permissions,
        roles,
      };
      return mergeRight(state, user);
    }
    case C.USER_LOGGED_IN: {
      const { userID, permissions, email, firstName, lastName } =
        action.payload;
      return mergeRight(state, {
        userID,
        permissions,
        email,
        firstName,
        lastName,
      });
    }
    case C.USER_LOGGED_OUT:
      return initialState;
    case C.RECEIVE_USER_LOG_IN_ERROR: {
      const { message } = action.payload;
      const user = {
        isFetching: false,
        showSnackBar: true,
        response: message,
      };

      return mergeRight(state, user);
    }
    case C.USER_CLOSE_SNACKBAR: {
      return mergeRight(state, {
        showSnackBar: false,
        response: "",
        error: "",
      });
    }

    case C.SET_USER_IS_FETCHING: {
      return mergeRight(state, {
        isFetching: action.payload,
      });
    }

    // on login & change password
    case C.REQUEST_AUTH_HEADERS:
    case C.REQUEST_CHANGE_PASSWORD: {
      return mergeRight(state, {
        isFetching: true,
        showSnackBar: false,
        response: "",
        error: "",
      });
    }
    case C.USER_CHANGE_PASSWORD_SUCCESS: {
      const user = {
        isFetching: false,
        showSnackBar: true,
        response: action.payload,
        errorMessage: "",
      };

      return mergeRight(state, user);
    }
    case C.USER_CHANGE_PASSWORD_FAILURE: {
      const user = {
        isFetching: false,
        showSnackBar: true,
        response: "",
        errorMessage: action.payload,
      };

      return mergeRight(state, user);
    }
    case C.HIDE_SNACKBAR: {
      return mergeRight(state, { showSnackBar: false });
    }
    default:
      return state;
  }
};

export default userUpdate;
